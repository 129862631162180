.container {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  background-color: var(--gray50);
  border-radius: 60px;
}

.item1 {
  text-align: center;
  margin-right: 8px;
}

.item2 {
  text-align: center;
  margin-right: 8px;
}

.button {
  cursor: pointer;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  height: 30px;
}

.icon {
  display: flex;
}

.selected {
  background-color: var(--gray900);
  color: var(--secondary);
}
