.custom-scrollbars__container {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.custom-scrollbars__content {
  height: calc(100% - 16px);
  overflow-y: auto;
  padding-right: 20px;
  padding-left: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__scrollbar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.custom-scrollbars__track-and-thumb {
  position: relative;
  width: 14px;
  height: 100%;
}

.custom-scrollbars__track {
  background-color: #fafafa;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-shadow: -1px 0px 0px 0px #f0f0f0 inset, 1px 0px 0px 0px #e8e8e8 inset;
}
.custom-scrollbars__thumb {
  background-color: #c1c1c1;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  top: 0;
  height: 20% !important;
}
