.successContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}
.successButton {
  width: 289px;
  height: 36px;
  background-color: #0086d6;
  border-radius: 5px;
  color: #ffffff;
  border: none;
}
.iconContainer {
  padding-top: 15%;
  margin-bottom: 20%;
}
.buttonContainer {
  margin-top: 5%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.buttonItem {
  margin: 4px;
}
