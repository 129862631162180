.centeredIcon-pm {
  justify-content: center;
  display: flex;
}

.text-pm {
  color: #565656;
}

.textstyle-pm {
  width: 130px;
}

.textContainer-pm {
  justify-content: center;
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}