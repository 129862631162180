.background-products {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.container-products {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landingContainer-products {
  padding-left: 15px;
  padding-right: 15px;
}
.thirdlandingPadding-products {
  padding-top: 50px;
}
.twolandingContainer-products {
  display: flex;
  flex-direction: row;
}
.titleContainer-products {
  padding-left: 10px;
  text-align: center;
}
/* Desktop */
@media only screen and (min-width: 1000px) {
  .container-products {
    display: flex;
    flex-direction: row;
  }
  .landingContainer-products {
    padding-left: 25px;
    padding-right: 25px;
  }
  .thirdlandingPadding-products {
    padding-top: 90px;
  }
  .titleContainer-products {
    padding-left: 40px;
    margin-bottom: 45px;
    text-align: left;
  }
}
/* Tablets */
@media screen and (min-width: 501px) and (max-width: 999px) {
  .container-products {
    display: flex;
    flex-direction: column;
  }
  .landingContainer-products {
    padding-left: 25px;
    padding-right: 25px;
  }
  .thirdlandingPadding-products {
    padding-top: 50px;
  }
  .titleContainer-products {
    padding-left: 40px;
    margin-bottom: 45px;
    text-align: left;
  }
}
