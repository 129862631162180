.checkboxround {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #299be8;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkboxround:checked {
  background-color: #299be8;
}
.container {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.labelStyle {
  color: #565656;
  margin-left: 7px;
}
