.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordContainer {
  width: 100%;
  display: flex;
  max-height: 100%;
  flex-direction: column;
}

.loader {
  border: 10px solid var(--secondary);
  border-top: 10px solid var(--primary);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
