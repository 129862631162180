.landing {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 173px;
  width: 175px;
  box-shadow: -5px 6px 10px 0px #00000040;
  background-color: #ffffff;
  border-radius: 15px;
  border: solid 2px transparent;
}
.landing:active {
  border: solid 2px #0086d6;
}
.iconContainer {
  margin-top: 28px;
}
.iconStyle {
  height: 77px;
  width: 63px;
}
.centeredSubtitle {
  text-align: center;
  padding-top: 5px;
  color: #515458;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
}

.landingContainer {
  padding-left: 15px;
  padding-right: 15px;
}
.titleStyle {
  padding-top: 13px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  color: #565656;
}
@media only screen and (min-width: 768px) {
  .landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 271px;
    width: 247px;
    box-shadow: -5px 6px 10px 0px #00000040;
    background-color: #ffffff;
    border-radius: 15px;
  }

  .landingContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
  .iconContainer {
    margin-top: 28px;
  }
  .iconStyle {
    height: 123px;
    width: 100px;
  }
  .centeredSubtitle {
    font-size: 12px;
  }
  .titleStyle {
    padding-top: 13px;
    font-size: 14px;
  }
}
