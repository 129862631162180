.order-container-OHC {
  background-color: #ffffff;
  width: 99%;
  border: 1px solid #40a4e0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mobile-data-container-OHC {
  flex-direction: row;

  align-items: center;
}
.data-container-OHC {
  margin-right: 4%;
}

.mobile-data-column-container-OHC {
  margin-left: 8px;
  margin-top: 5px;
}

.first-row-container-OHC {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.detail-container-OHC {
  background-color: #eeeeee;
  height: 39px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.title-container-OHC {
  display: flex;
  margin-left: 22px;
  margin-top: 13px;
  width: 65%;
}
.text-style-OHC {
  color: #565656;
  line-height: 10px;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.text-style-big-OHC {
  color: #565656;
}
.icon-text-container-OHC {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 15px;
}
.main-icon-text-container-OHC {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 15px;
}
.icon-style-OHC {
  width: 12px;
  height: 10px;
  margin-right: 15px;
}
.delivered-text-style-OCH {
  color: #00bb2d;
}
.rejected-text-style-OCH {
  color: #e41b1b;
}
@media only screen and (max-width: 414px) {
  .detail-container-OHC {
    height: 24px;
  }
  .text-style-OHC {
    padding-top: 5px;
    text-align: left;
    line-height: 0px;
  }

  .first-row-container-OHC {
    justify-content: space-between;
  }
  .order-container-OHC {
    background-color: #ffffff;
    width: 99%;
    border: 1px solid #40a4e0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
