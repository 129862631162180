.background-sch {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 72px;
  padding-top: 32px;
  height: 100vh;
}
.commonContainer-sch {
  flex-direction: row;
  align-items: center;
  display: flex;
  left: 0;
}
.iconContainer-sch {
  margin-right: 17px;
  cursor: pointer;
}
.italicBold-sch {
  font-style: italic;
  font-weight: 700;
}
.iconEcoFriendly-sch {
  width: 98px;
  height: 98px;
}
.neutralGray900-sch {
  color: #565656;
}
.container-sch {
  width: 699px;
  height: 491px;
  background-color: #ffffff;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 25px;
  padding-top: 15px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.greenButton-sch {
  width: 654px;
  height: 56px;
  border-radius: 14px;
  background-color: #00bb2d;
  cursor: pointer;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
.greenButtonContainer-sch {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 30px;
}
.commonWeight {
  font-weight: 500;
  line-height: 1.5;
}
.modalStyle-sch {
  width: 344px;
  height: 291px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.modalContentStyle-sch {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.greyFontColor-sch {
  color: #565656;
  text-align: center;
  width: 312px;
}
.greenModalButton-sch {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 289px;
  height: 36px;
  border-radius: 8px;
  border: none;
  background-color: #00bb2d;
  cursor: pointer;
  color: white;
}

@media screen and (min-width: 501px) and (max-width: 999px) {
  .container-sch {
    width: 499px;
    height: 551px;
    background-color: #ffffff;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 15px;
    box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
  }
}
