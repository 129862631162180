.rowSale{
	width:100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	padding: 0px 8px 0px 8px;
}

.modalCloseButton {
	width: 20px;
	padding-right: 32px;
}

.modalContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.modalContent {
	background-color: #EEEEEE;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px;
	margin: 16px;
	border-radius: 8px;
}

.modalRow {
	display: flex;
	gap: 8px;
}


.modalRowButtons {
	width: 100%;
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
}

.modalContentText {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
}

.gray {
	color: var(--gray900);
}

.primary {
	color: var(--primary);
}

.error {
	color: var(--error);
}

.modalTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;

	display: flex;
	align-items: center;
	color: #565656;
}

.modalHeader {
	display: flex;
	width: 100%;
	padding: 0px 16px 0px 16px;
	align-items: center;
	justify-content: space-between;
}
.saleCard{
	background: #EEEEEE;
	border-radius: 5px;
	cursor: pointer;
}
.column{
	width:100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 8px;
}

.columnId{
	width:10%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 8px;
}
.negative{
	color: var(--error)
}
.columnEnd{
	width: 40%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
	padding-right: 16px;
}