.container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 323px;
  /* height: 616px; */
  background-color: white;
  border-radius: 15px;
}
.iconStyle {
  height: 73px;
  width: 73px;
}
.iconContainer {
  justify-content: center;
  display: flex;
  align-content: center;
  margin-top: 10px;
}
.textContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.commontTextLineHeight {
  line-height: 0 !important;
  /* height: 10px */
}
.yellowButtonContainer {
  width: 313px;
  height: 56px;
  border-radius: 14px;
  background-color: #f6c412;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
.blueButtonContainer {
  width: 313px;
  height: 56px;
  border-radius: 14px;
  background-color: #0086d6;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 14px;
}
.buttonsContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rowContainer {
  flex-direction: row;
  display: flex;
}
.blue {
  color: #0086d6;
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .container {
    width: 363px;
  }
  .yellowButtonContainer {
    width: 343px;
  }
  .blueButtonContainer {
    width: 343px;
  }
}
