.background-inventory {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
  margin-top: 20px;
}
.container-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 840px;
  background: white;
  border-radius: 20px;
  box-shadow: -10px 14px 24px rgba(0, 0, 0, 0.17);
}
.container-search-name {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}
.container-category {
  width: 95%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}
.text-productempty {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #565656;
}
.text-footerempty {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #565656;
}
.box-textfooter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 350px;
  height: 53px;
  margin: 0px 0px 0px 15px;
}

.box-text {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 362px;
  height: 105px;
  margin: 0px 0px 0px 15px;
}
.box-image {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 345.7px;
  height: 176px;
  margin: 0px 0px 0px 20px;
}
.container-leyendimage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 392px;
  height: 311px;
  background-color: white;
  border: 1px solid #0086d6;
  border-radius: 15px;
}
.container-leyendfooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 392px;
  height: 73px;
  background-color: white;
  border: 1px solid #00bb2d;
  border-radius: 15px;
  margin-top: 17px;
}

.container-navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container-inventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 823px;
  height: 100%;
  background-color: white;
  box-shadow: -9px 14px 44px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border-bottom-right-radius: 20px;
  margin-left: 19px;
}
.container-table-inventory {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 783px;
  height: 100%;
  margin: 25px 20px 0px 20px;
}
.container-cards-inventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 783px;
  height: 470px;
}
.container-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 64px;
  margin-bottom: 15px;
}
.container-body-liquidate {
  width: 340px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 20px 20px;
}
.container-title-liquidate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.name-product-liquidate {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 5px;
}
.text-description-short {
  font-size: 20px;
  line-height: 26px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: black;
  margin: 0;
}
.text-description-full {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}
.text-footer-input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #565656;
  margin: 0;
}
.form-inputs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  margin: 0px;
}
.input-stock {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0px;
  margin-left: 35px;
}
.input-price {
  margin: 0px;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.button-submit-liquidation {
  width: 347px;
  height: 56px;
  background-color: #00b744;
  border-radius: 14px;
  color: #ffffff;
  margin-top: 10%;
  border: none;
  justify-content: "center";
  align-content: "center";
  display: "flex";
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.button-submit-liquidation:active {
  background-color: #01a03c;
  color: #ffffff;
  border-color: #01a03c;
}
.button-submit-liquidation:disabled {
  background-color: var(--gray300);
  color: var(--secondary);
  border-color: #01a03c;
}
.error-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
  gap: 10px;
  height: 30px;
  position: relative;
  background-color: #ffea79;
}
.text-error-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #565656;
}
.modalsmallInv {
  height: 350px;
  width: 320px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.greyColorInv {
  color: #565656;
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .container-body {
    width: 840px;
    border-radius: 5px;
    border-radius: 20px;
  }
  .background-inventory {
    background-image: url("../../assets/images/bg_site.png");
    background-size: cover;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .container-body {
    width: 840px;
    border-radius: 5px;
    border-radius: 20px;
  }
  .background-inventory {
    background-image: url("../../assets/images/bg_site.png");
    background-size: cover;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .container-body {
    width: 414px;
    height: 641px;
    border-radius: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .container-cards-inventory {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1300px) {
  .background-inventory {
    background-image: url("../../assets/images/bg_site.png");
    background-size: cover;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container-body {
    width: 414px;
    height: 641px;
    border-radius: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .container-cards-inventory {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .input-stock {
    margin-left: 0px;
  }
}
