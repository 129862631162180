.outline-input {
  --border-radius: 25px;
  --color: rgba(0, 0, 0, 0.87);
  --color__secondary: #565656;
  --color__disabled: rgba(0, 0, 0, 0.38);
  --color__inactive: rgba(0, 0, 0, 0.24);
  --color__border: var(--gray900);
  --color__focused: var(--gray900);
  --color__error: var(--error);
  --font-size: 11;
  --label-font-size__active: 12;
  --label-scale-factor: calc(var(--label-font-size__active) / var(--font-size));
  font-family: "Montserrat";
  margin: 10px 0;
}
.disabled-outline-input {
  --color__border: rgba(0, 0, 0, 0.24);
}

.floatinglabel {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.wrapper {
  width: 100%;
}
.outline-input .field-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}
.outline-input .field-wrapper input,
.outline-input .field-wrapper label {
  font-size: calc(var(--font-size) * 1px);
}
.outline-input .field-wrapper input {
  background: transparent;
  border: none;
  color: var(--color);
  padding: 16px 14px;
  outline: none;
  width: 100%;
}
.outline-input .field-wrapper input:-moz-ui-invalid {
  box-shadow: none !important;
}
.outline-input .field-wrapper i {
  color: var(--color);
  margin: 0 12px;
  pointer-events: none;
  width: 24px;
}
.outline-input .field-wrapper label {
  color: var(--color__secondary);
  pointer-events: none;
}
.outline-input .field-wrapper label.floating {
  left: 14px;
  max-width: calc(100% - 28px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  transition-duration: 0.4s;
  transform-origin: 0 0;
  transition-property: color, font-size, padding, transform, max-width, left;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  white-space: nowrap;
  font-weight: 600;
}
.outline-input .field-wrapper label.reference {
  max-width: var(--label-scale-width);
  padding: 0 4px 0 0;
  transform: scale3d(var(--label-scale-factor), var(--label-scale-factor), 1);
}

.outline-input .field-wrapper._leading-icon label.floating:not(.reference),
.outline-input .field-wrapper._trailing-icon label.floating:not(.reference) {
  max-width: calc(100% - 62px);
}
.outline-input
  .field-wrapper._leading-icon._trailing-icon
  label.floating:not(.reference) {
  max-width: calc(100% - 96px);
}
.outline-input .field-wrapper._leading-icon input {
  padding-left: 0;
}
.outline-input .field-wrapper._leading-icon label.floating:not(.reference) {
  left: 48px;
}
.outline-input .field-wrapper._trailing-icon input {
  padding-right: 0;
}
.outline-input .field-wrapper .borders {
  display: flex;
  flex-direction: row;
  height: 40px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.outline-input .field-wrapper .borders .border {
  border: 1px solid var(--color__border);
  border-color: var(--color__border);
  border-style: solid;
  transition-duration: 0.4s;
  transition-property: border-color, width;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.outline-input .field-wrapper .borders .middle {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.outline-input .field-wrapper .borders .middle .top-borders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.outline-input .field-wrapper .borders .middle .top-borders .border {
  border-width: 1px 0 0;
  flex: unset;
}
.outline-input .field-wrapper .borders .middle .top-borders .border.start {
  width: calc(var(--label-active-width) / 2);
}
.outline-input .field-wrapper .borders .middle .border {
  flex: 1 1 auto;
  width: 100%;
}
.outline-input .field-wrapper .borders .middle .border.bottom {
  border-width: 0 0 1px 0;
}
.outline-input .field-wrapper .borders .right,
.outline-input .field-wrapper .borders .left {
  width: 20px;
}
.outline-input .field-wrapper .borders .left {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  border-width: 1px 0 1px 1px;
}
.outline-input .field-wrapper .borders .right {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-width: 1px 1px 1px 0;
}
.outline-input
  .field-wrapper._floating-label._focused
  .borders
  .top-borders
  .start,
.outline-input
  .field-wrapper._floating-label._has-value
  .borders
  .top-borders
  .start {
  width: 0;
}
.outline-input
  .field-wrapper._floating-label._focused
  .borders
  .top-borders
  .end,
.outline-input
  .field-wrapper._floating-label._has-value
  .borders
  .top-borders
  .end {
  width: calc(100% - var(--label-active-width, 0) - 4px);
}
.outline-input
  .field-wrapper._floating-label._focused
  label.floating:not(.reference),
.outline-input
  .field-wrapper._floating-label._has-value
  label.floating:not(.reference) {
  left: 22px;
  max-width: var(--label-scale-width);
  padding: 0 4px 0 0;
  transform: translate3d(
      0,
      calc(-100% - var(--label-font-size__active) * 0.5px),
      0
    )
    scale3d(var(--label-scale-factor), var(--label-scale-factor), 1);
}
.outline-input .field-wrapper._focused label.floating {
  color: var(--color__focused);
  font-weight: 600;
}
.outline-input .field-wrapper._focused .borders .top-borders .border {
  border-width: 1px 0 0;
}
.outline-input .field-wrapper._focused .borders .border {
  border: 1px solid var(--color__focused);
}
.outline-input .field-wrapper._focused .borders .border.bottom {
  border-width: 0 0 1px 0;
}
.outline-input .field-wrapper._focused .borders .border.left {
  border-width: 1px 0 1px 1px;
}
.outline-input .field-wrapper._focused .borders .border.right {
  border-width: 1px 1px 1px 0;
}
.outline-input .field-wrapper._invalid .borders .border {
  border: 1px solid var(--color__error);
  border-width: 1px 0 1px 1px;
}
.outline-input .field-wrapper._invalid label.floating {
  color: var(--color__error);
  font-weight: 600;
}
.hints {
  width: 90%;
}
.select {
  width: 100%;
  margin: 10px;
  border: 0px none;
  box-shadow: none;
  border-color: 0px #fff;
}
.option-style-input {
  border: none;
  box-shadow: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  white-space: pre-line;
  font-size: 0.7rem;
  color: var(--gray900);
}
::-moz-placeholder {
  /* Firefox 19+ */
  white-space: pre-line;
  position: relative;
  top: -7px;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--gray900);
}
:-ms-input-placeholder {
  /* IE 10+ */
  white-space: pre-line;
  position: relative;
  top: -7px;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--gray900);
}
:-moz-placeholder {
  /* Firefox 18- */
  white-space: pre-line;
  position: relative;
  top: -7px;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--gray900);
}

.outline-input .hints {
  color: var(--color__secondary);
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 4px 12px 0 12px;
}
.outline-input .hints .messages {
  flex: 1 1 auto;
}
.outline-input .hints .errorInput {
  color: var(--color__error);
  font-family: Montserrat;
  font-size: 0.813rem;
  font-weight: 600;
  line-height: 1.063rem;
  letter-spacing: 0px;
}

.outline-input .hints .char-counter {
  margin: 0 0 0 12px;
}
.dark .outline-input {
  --color: #fff;
  --color__secondary: rgba(255, 255, 255, 0.7);
  --color__disabled: rgba(255, 255, 255, 0.3);
  --color__inactive: rgba(255, 255, 255, 0.24);
  --color__border: var(--color__disabled);
  --color__focused: #b388ff;
  --color__error: #ff5252;
}
.dark .outline-input i.material-icons-two-tone {
  filter: invert(1);
}
.dark .outline-input label.floating {
  color: var(--color__disabled);
  font-weight: 600;
}

.trailingWrapper {
  display: flex;
  margin-right: 8px;
  width: 25px;
  cursor: pointer;
}
