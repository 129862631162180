.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  top: -10px;
}

.menuItem {
  width: 100%;
  height: 32px;
  color: var(--gray900);
  font-size: 0.875rem;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}

.menuItem:hover {
  background-color: var(--primary);
  color: var(--secondary);
}
