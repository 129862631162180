@import "../../components/text/Text.css";

.background-orders {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.all-orders-container {
  background-color: #ffffff;
  width: 90%;
  left: 0;
  right: 0;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scroll-orders-container {
  width: 100%;
  overflow-y: scroll;
}
.record-orders-container {
  height: 75%;
}

.orders-header-tab-container {
  height: 40px;
  width: 100%;
  background-color: #eeeeee;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.order-header-text-container {
  width: 25%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}
.order-header-text-container-selected {
  height: 40px;
  width: 25%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.order-header-text-style {
  color: #63666a;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.no-orders-text-style {
  color: #565656;
  margin-left: 60px;
  margin-right: 60px;
  text-align: center;
}
.no-products-icon-container {
  height: 204px;
  width: 252px;
}
.no-products-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}
.top-margin {
  margin-top: 21px;
}
.bottom-margin-history {
  margin-bottom: 21px;
}
.order-select-container {
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 55%;
}
.centered-orders-container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.search-input-style {
  width: 45%;
}
.history-search-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.selector-divider-style {
  height: 31px;
  background-color: #dddddd;
  width: 1px;
  margin-left: 2%;
  margin-right: 2%;
}
@media only screen and (max-width: 414px) {
  .all-orders-container {
    background-color: #ffffff;
    width: 90%;
    height: 74%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .order-header-text-style {
    color: #63666a;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
  }
  .history-search-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }
  .search-input-style {
    width: 90%;
  }
  .order-select-container {
    width: 90%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .history-search-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }
  .search-input-style {
    width: 90%;
  }
  .order-select-container {
    width: 90%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 481px) {
  .history-search-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }
  .search-input-style {
    width: 90%;
  }
  .order-select-container {
    width: 90%;
    margin-bottom: 20px;
  }
}
