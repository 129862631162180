.conteiner {
    max-width: 1300px;
    height: 800px;
    border: 1px;
    border-radius: 15px;
    box-shadow: 0px 4px 14px 0px #00000026;
    background-color: white;
    margin-bottom: 35px;
    align-items: center;
    align-content: center;
}

.conteiner-dashboard {
    height: calc(100vh -100px) !important;
}

@media only screen and (min-width: 1585px) {
    .conteiner-dashboard {
        width: 1300px;
    }
}

@media only screen and (max-width: 1585px) {
    .conteiner-dashboard {
        width: 1000px;
    }
}

@media only screen and (max-width: 1280px) {
    .conteiner-dashboard {
        width: 800px;
    }
}

@media only screen and (max-width: 1080px) {
    .conteiner-dashboard {
        width: 700px;
    }
}

@media only screen and (max-width: 968px) {
    .conteiner-dashboard {
        width: 600px;
    }
}
