.container {
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.button {
  border-radius: 24px;
  background-color: var(--secondary);
  width: 85px;
  height: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--gray10c);
  border: 1px solid #565656;
  padding: 0.5rem;
  font-family: sans-serif;
  cursor: pointer;
  margin-top: 1rem;
}

.errorIcon {
  width: 16px;
}
.error {
  color: var(--error);
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
