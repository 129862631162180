.xIconContainer-reg {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
  cursor: pointer;
}
.IconContainer-reg {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
}
.backdrop-reg {
  position: fixed;
  background: rgba(0, 0, 0, 0.49);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-main-reg {
  position: absolute;
  background: white;
  width: 600px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 15px;
  background-color: #f0f3f5;
}
.modal-reg {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.main-container-reg {
  margin-left: 25px;
  margin-right: 25px;
  height: 95%;
  overflow-y: scroll;
}
.textAlertStyle-reg {
  color: white;
}
.alertTextContainer-reg {
  margin-top: 37px;
  background-color: #565656;
  height: 44px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.iconAlertStyle-reg {
  color: white;
  margin-right: 10px;
}
.searchInputStyle-reg {
  margin-top: 37px;
  margin-left: 28px;
  margin-right: 28px;
  flex-direction: column;
}
.confirmButton-reg {
  width: 549px;
  height: 56px;
  background-color: #00bb2d;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  color: white;
  bottom: 0;
  position: absolute;
  margin-bottom: 23px;
  margin-left: 28px;
}
.confirmButtonDeactive-reg {
  width: 549px;
  height: 56px;
  background-color: #bbbbbb;
  border-radius: 14px;
  border: none;
  color: white;
  bottom: 0;
  position: absolute;
  margin-bottom: 23px;
  margin-left: 28px;
}
.listContainer-reg {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 15px;
  max-height: 138px;
  padding-bottom: 20px;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.iconRegionContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.iconLocationContainer-reg {
  margin-left: 10px;
  margin-right: 14px;
}
.selectedItemContainer-reg {
  height: 36px;
  padding-left: 18px;
  padding-right: 22px;
  background-color: #cfedff;
  border-radius: 50px;
  display: inline-block;
}
.innerSelectedItemContainer-reg {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.iconXStyle-reg {
  margin-left: 15px;
  cursor: pointer;
}
