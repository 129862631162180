.xIconContainer-dm {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
  padding-bottom: 13px;
}
.IconContainer-dm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.backdrop-dm {
  position: fixed;
  background: rgba(0, 0, 0, 0.49);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-main-dm {
  position: absolute;
  background: white;
  width: 90%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 15px;
}

.scroll-container-dm {
  overflow-y: scroll;
  height: 95%;
}

.modal-dm {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.detail-title-style {
  color: #565656;
}
.detail-line-height {
  line-height: 0px;
}
.address {
  font-family: "Montserrat", Georgia, "Times New Roman", Times, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.no-line-height {
  line-height: 0px;
}
.common-no-margin {
  margin: 5px;
}
.common-text-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #565656;
  margin-left: 16px;
  margin-right: 16px;
}
.description-text-style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 16px;
  margin-right: 16px;
}
.comment-text-style {
  font-style: italic;
}
.ingredient-type-style {
  margin-left: 15px;
  color: #565656;
}
.accept-button {
  width: 170px;
  height: 56px;
  background-color: #00bb2d;
  border-radius: 14px;
  margin-left: 24px;
}
.reject-button {
  width: 170px;
  height: 56px;
  background-color: #565656;
  border-radius: 14px;
  cursor: pointer;
}
.common-button-text-style {
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.blue-line-divider {
  height: 1px;
  background-color: #0086d6;
  width: 90%;
}
.centered-od {
  margin-bottom: 16px;
  margin-top: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.download-pdf-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blue-button-style {
  height: 56px;
  border-radius: 14px;
  background-color: #0086d6;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.blue-button-text-style {
  padding-left: 9px;
  padding-right: 9px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.has-uber-container {
  height: 41px;
  border-radius: 5px;
  background-color: #565656;
  margin-left: 16px;
  margin-right: 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.has-schedule-container {
  border-radius: 5px;
  background-color: #74bf00;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.has-purchase-text-container {
  margin-left: 10px;
  padding-top: 27px;
  padding-bottom: 27px;
}
.common-white-text-style {
  color: #ffffff;
  text-align: left;
}
.print-text-style-odm {
  color: #565656;
}
.text-icon-uber-container {
  margin-right: 11px;
  margin-left: 11px;
}
.common-margin {
  margin-left: 24px;
}
.blue-button-common-margin {
  margin-left: 15px;
  margin-right: 15px;
}
.icon-text-container-ODM {
  justify-content: center;
  display: flex;
  align-items: center;
}
.delivered-text-style-ODM {
  color: #00bb2d;
  margin-left: 10px;
}
.rejected-text-style-ODM {
  color: #e41b1b;
  margin-left: 10px;
}
.centered-text-style-ODM {
  text-align: center;
}
.common-row-style {
  display: flex;
  flex-direction: row;
}
.modalsmall-ODM {
  height: 307px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.voucherButton {
  height: 50px;
  width: 345px;
  border-radius: 5px;
  background-color: #00bb2d;
  justify-content: center;
  display: flex;
  align-content: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 20px;
}
.hasVoucherButton {
  height: 50px;
  width: 345px;
  border-radius: 5px;
  background-color: #0086d6;
  justify-content: center;
  display: flex;
  align-content: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.voucherTextStyle {
  color: #ffffff;
}
.vouchercontainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .modal-main-dm {
    position: absolute;
    background: white;
    width: 35%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: 0px 0px 16px 0px;
    border-radius: 15px;
  }
  .scroll-container-dm {
    overflow-y: scroll;
    height: 95%;
  }
}
.icon-background-odm {
  background-color: #ffffff;
  height: 42px;
  width: 42px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: -15px;
  box-shadow: 1px 5px 6px 2px rgba(0, 0, 0, 0.24);
}
.display-block-dm {
  display: block;
}
.common-space-odm {
  padding-left: 8px;
}

.display-none-dm {
  display: none;
}
.modalSuccess-ODM {
  height: 280px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.modalError-ODM {
  height: 298px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
