.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.wrapper {
  width: 90%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.inputContainer {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}

.form {
  padding: 20px;
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  justify-content: center;
  align-items: center;
  box-shadow: -14px 14px 44px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-start;
  width: 100%;
}

.textWidth {
  min-width: max-content;
}

.divider {
  height: 0px;
  width: 80%;

  border: 1px solid #bbbbbb;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.rowItem1 {
  margin-right: 10px;
  width: 50%;
  height: 100%;
}
.rowItem2 {
  margin-left: 10px;
  width: 50%;
}

.liquidateError {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 32px;
  background-color: var(--yellowPost);
  margin-right: -20px;
}
.warningIcon {
  margin-left: 20px;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 100%;
  }
  .form {
    width: 100%;
  }
  .container {
    width: 100%;
  }
}
