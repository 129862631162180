.loader {
    border: 10px solid var(--secondary);
    border-top: 10px solid var(--primary);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    opacity: 1;
}

.container {
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: var(--gray50);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
