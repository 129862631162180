.background-home {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landingContainer-home {
  padding-left: 15px;
  padding-right: 15px;
}
.thirdlandingPadding {
  padding-top: 50px;
}
.twolandingContainer {
  display: flex;
  flex-direction: row;
}
.titleContainer-home {
  padding-left: 10px;
  padding-bottom: 28px;
  text-align: center;
}
.buttonContainer-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.blueButton {
  background-color: #40a4e0;
  border-color: none;
  height: 55px;
  width: 95%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 10px;
}
.greenButton-home {
  width: 95%;
  background-color: #029e28;
  border-color: #029e28;
  height: 55px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  margin-top: 50px;
  margin-left: 10px;
}
.greenButton-home:active {
  background-color: #00bb2d;
  border-color: #00bb2d;
}
.buttonText-home {
  color: white;
}
.left-icon-home {
  margin-right: 28px;
}
/* Desktop */
@media only screen and (min-width: 1000px) {
  .container-hm {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .container-home {
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 50px;
  }
  .landingContainer-home {
    padding-left: 25px;
    padding-right: 25px;
  }
  .thirdlandingPadding {
    padding-top: 163px;
  }
  .titleContainer-home {
    margin-top: 70px;
    text-align: left;
  }

  .noLineHeight-home {
    line-height: 0px;
  }
  .greenButton-home {
    cursor: pointer;
    width: 95%;
    background-color: #029e28;
    border-color: #029e28;
    height: 55px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    margin-top: 50px;
  }

  .blueButton {
    cursor: pointer;
    width: 95%;
    background-color: #40a4e0;
    border-color: none;
    height: 55px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
/* Tablets */
@media screen and (min-width: 501px) and (max-width: 999px) {
  .container-home {
    display: flex;
    flex-direction: column;
  }
  .landingContainer-home {
    padding-left: 25px;
    padding-right: 25px;
  }
  .thirdlandingPadding {
    padding-top: 50px;
  }
  .titleContainer-home {
    padding-left: 40px;
    padding-bottom: 28px;
    text-align: left;
  }
  .greenButton-home {
    width: 100%;
    background-color: #029e28;
    border-color: #029e28;
    height: 55px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    margin-top: 100px;
  }

  .blueButton {
    width: 100%;
    background-color: #40a4e0;
    border-color: none;
    height: 55px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    margin-top: 50px;
  }
}
