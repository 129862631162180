.background-sConfig {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 72px;
  padding-top: 32px;
  height: 100vh;
}
.innerContainer-sConfig {
  width: 786px;
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: #ffffff;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.commonContainer-sConfig {
  flex-direction: row;
  display: flex;
}
.iconContainer-sConfig {
  margin-right: 17px;
  cursor: pointer;
}

.communesContainer {
  min-height: max-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.storeNameContainer-sConfig {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.textContainer-sConfig {
  margin-left: 23px;
}
.textStyle-sConfig {
  color: #565656;
  line-height: 0px;
}
.storeCategoryTextStyle-sConfig {
  color: #818181;
  line-height: 0px;
}
.grey900 {
  color: #565656;
}
.editableInfoContainer-sConfig {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.commonTitleWidth-sConfig {
  width: 157px;
}
.telephoneTitleWidth-sConfig {
  width: 237px;
}
.inputStyle-sConfig {
  background-color: white;
  border: #ffffff;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 10px;
}
.hasPointer-sConfig {
  cursor: pointer;
}
.inputPhoneStyle-sConfig {
  background-color: white;
  border: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}
.inputEmailStyle-sConfig {
  padding-top: 18px;
  background-color: white;
  border: #ffffff;
  /* height: 50px; */
  width: 40%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1rem;
}

.storeColumn-sConfig {
  flex-direction: row;
  display: flex;
}
.minAmountRowContainer-sConfig {
  flex-direction: row;
  display: flex;
}
.noOnlineStoreContainer-sConfig {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.infoIcon-sConfig {
  margin-right: 7px;
}
.commonHeight {
  height: 20px;
}
.greenButton-sConfig {
  width: 345px;
  height: 48px;
  border-radius: 5px;
  background-color: #00bb2d;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.daysContainer-sConfig {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.dayContainerBlue-sConfig {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #40a4e0;
  border-color: #40a4e0 1px solid;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
}
.dayContainerGrey-sConfig {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #565656;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #565656;
  cursor: pointer;
  margin-right: 20px;
}
.centered-sConfig {
  align-items: center;
}
.spaceBetween-sConfig {
  justify-content: space-between;
}
.commonWidth-sConfig {
  width: 45%;
}
.deliverWidth-sConfig {
  width: 200px;
}
.divider-sConfig {
  width: 45%;
  background-color: #676767;
  height: 1px;
}
.lineTitleStyle-sConfig {
  width: 100%;
  background-color: #cccccc;
  height: 1px;
  margin-left: 6px;
  margin-right: 6px;
}
.lineCommuneStyle-sConfig {
  width: 98%;
  background-color: #cccccc;
  height: 1px;
  margin-bottom: 20px;
}
.divider-width {
  width: 165px;
  margin-top: 10px;
}
.commonColumn-sConfig {
  flex-direction: column;
  display: flex;
}
.linkStyle-sConfig {
  font-style: italic;
  color: #40a4e0;
}
.onlineContainer-sq {
  width: 364px;
  margin-left: 44px;
}
.commonColumnContainer-sq {
  width: 344px;
}
.commonMarginLeft {
  margin-left: 15px;
}
.amountInput-sConfig {
  width: 102px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: center;
  font-weight: 700;
  font-size: 14;
  font-family: Montserrat;
}
.regionButton-sConfig {
  width: 620px;
  height: 44px;
  background-color: #0086d6;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.ContinueButton-sConfig {
  width: 821px;
  height: 44px;
  background-color: #0086d6;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}
.regionButton-sConfig:active {
  background-color: #40a4e0;
}
.buttonWidth-sConfig {
  width: 786px;
}
.plusIconContainer-sConfig {
  position: absolute;
  top: 196px;
  left: 385px;
  cursor: pointer;
  z-index: 1;
}
.minAmountContainer {
  width: 387px;
}
.logo-img-sConfig {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-left: 13px;

  position: relative;
  z-index: 0;
}
.timeInputContainer-sConfig {
  border: none;
  cursor: pointer;
}
.regionSelectedContainer-sConfig {
  width: 98%;
  border-radius: 5px;
  background-color: #eeeeee;
  margin-top: 20px;
  padding-left: 13px;
  padding-top: 13px;
  padding-bottom: 20px;
}
.regionTitleStyle-sConfig {
  flex-direction: row;
  display: flex;
}
.comunneButtonContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}
.trashIconContainer-sConfig {
  cursor: pointer;
  margin-right: 14px;
}
.comuneButton-sConfig {
  width: 197px;
  height: 44px;
  border-radius: 5px;
  background-color: #565656;
  align-items: center;
  flex-direction: row;
  display: flex;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.comuneButtonTextStyle-sConfig {
  font-size: 12px;
}
.comuneButton-sConfig:active {
  background-color: #c4c4c4;
}
.deactiveStore-sConfig {
  width: 344px;
  height: 55px;
  background-color: #eeeeee;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.programmedContainer-sConfig {
  width: 100%;
  height: 44px;
  border-radius: 5px;
  background-color: #74bf00;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.commonTopMargin-sConfig {
  margin-top: 20px;
}
.container-body-calendar-sConfig {
  width: 388px;
  height: 558px;
  border-radius: 7px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: scroll;
}

.disableStoreTimeContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.timeIconContainer {
  margin-left: 30px;
}
.calendarText-sConfig {
  text-align: left;
  margin-left: 5px;
}
.communeTextAlign-sConfig {
  text-align: left;
}
.centerTextAlign-sConfig {
  text-align: center;
}
.redCalendarButton-sConfig {
  background-color: #e41b1b;
  border-radius: 14px;
  width: 343px;
  height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  cursor: pointer;
}
.greyButtonCalendar {
  color: #717171;
  cursor: pointer;
}
.buttonsContainer-sConfig {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-bottom: 13px;
}
.modalsmall-sConfig {
  height: 313px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.successModalSmaill-sConfig {
  height: 298px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.programmedContainer {
  width: 384px;
}

.containerCommune-sConfig {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.container-updateStore {
  width: 344px;
  height: 258px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding-left: 25px;
  padding-right: 25px;
}
.innerUpdateStoreContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.alertIcon-sConfig {
  width: 102px;
  height: 104px;
}
.dontSave-sConfig {
  width: 135px;
  height: 36px;
  background-color: #ff1e2f;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}
.save-sConfig {
  width: 312px;
  height: 36px;
  background-color: #00bb2d;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}
.redBlueButtonsmargin {
  width: 31px;
}
.alertModal-sConfig {
  height: 150px;
  width: 250px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: 8px;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1000px) {
  .communesContainer {
    width: 48%;
    gap: 16px;
  }
  .containerCommune-sConfig {
    gap: 16px;
  }
}

@media screen and (min-width: 501px) and (max-width: 999px) {
  .innerContainer-sConfig {
    width: 486px;
    padding-top: 28px;
    padding-bottom: 28px;
    background-color: #ffffff;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 7px;
    box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
  }
  .storeColumn-sConfig {
    flex-direction: column;
    display: flex;
  }
  .onlineContainer-sq {
    width: 344px;
    margin-left: 0px;
  }
  .deactiveStore-sConfig {
    width: 100%;
    height: 55px;
    background-color: #eeeeee;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .commonColumnContainer-sq {
    width: 444px;
  }
  .onlineContainer-sq {
    width: 92%;
    margin-left: 0px;
  }
  .ContinueButton-sConfig {
    width: 520px;
    height: 44px;
    background-color: #0086d6;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  .programmedContainer {
    width: 100%;
  }
  .minAmountContainer {
    width: 523px;
  }
  .telephoneTitleWidth-sConfig {
    width: 437px;
  }
  .deliverWidth-sConfig {
    width: 300px;
  }
  .plusIconContainer-sConfig {
    position: absolute;
    left: 390px;
    top: 195px;
    cursor: pointer;
    z-index: 1;
  }
  .containerCommune-sConfig {
    columns: 1 auto;
  }
  .container-body-calendar-sConfig {
    width: 388px;
    height: 558px;
    border-radius: 7px;
    background-color: #ffffff;
    padding: 8px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -60%);
    padding-left: 25px;
    padding-right: 25px;
    overflow-y: scroll;
  }
}
