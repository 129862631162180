.container {
  width: 100%;
  height: 103px;
  display: flex;
  column-count: 2;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--secondary);
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.icon {
  height: auto;
  max-width: 50px;
}
@media only screen and (min-width: 768px) {
  .container {
    width: 100%;
  }
  .icon {
    width: 50px;
  }
}
