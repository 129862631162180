.buttonHelp {
    width: 85%;
    height: 52px;
    background-color: #00b744;
    border-radius: 14px;
    color: #ffffff;
    margin-top: 5%;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .buttonHelp:active {
    background-color: #01a03c;
    color: #ffffff;
    border-color: #01a03c;
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    margin-left: 5%;
    margin-right: 5%;
    color:#565656
  }
  .whatsappIcon {
    margin-left: 3%;
  }
  .centeredIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }
  