.progress {
  height: 10px;
  width: 70%;
  border-radius: 2px;
  box-shadow: inset 0px 0px 0 rgba(0, 0, 0, 0.2),
    inset 0 -1px 0 rgba(255, 255, 255, 0.25),
    inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: var(--gray400);
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.bar {
  width: 0%;
  height: 10px;
  background-color: var(--successDark);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bar-100 {
  width: 100%;
}

.error {
  background-color: var(--error);
}
