.containertop {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.buttonContainer {
  height: 64px;
  width: 283px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid;
  border-color: #0086d6;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.buttonContainerDisabled {
  height: 64px;
  width: 283px;
  pointer-events: none;
  background-color: white;
  border-radius: 15px;
  border: 1px solid;
  border-color: #bbbbbb;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.buttonTextStyle {
  color: #565656;
}
.buttonTextStyleDisabled {
  color: #bbbbbb;
}
.container {
  background-color: #f5f5f5;
  width: 329px;
  height: 423px;
  border-radius: 10px;
  border: 1px solid;
  border-color: #0086d6;
  flex-direction: column;
  display: flex;
  margin-top: 50px;
}
.titleStyle {
  color: #565656;
}
.commonMargin {
  margin-left: 17px;
  margin-right: 17px;
}
.backIconContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: 17px;
}
.arrowStyle {
  height: 11px;
  width: 11px;
  margin-right: 12px;
  margin-top: 12px;
}
.inputStyle {
  width: 220px;
  height: 56px;
  border-radius: 24px;
  border: 1px solid;
  border-color: #898989;
  font-size: 36px;
  font-weight: 600;
  font-family: Montserrat;
  color: #565656;
}
.inputStyle::placeholder {
  font-size: 36px;
}
#inputID::placeholder {
  color: #bbbbbb;
  border: none;
}
.blue {
  color: #0086d6;
  margin-left: 10px;
}
.textMargin {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .container {
    background-color: #f5f5f5;
    width: 419px;
    height: 523px;
    border-radius: 10px;
    border: 1px solid;
    border-color: #0086d6;
    flex-direction: column;
    display: flex;
  }
  .buttonContainer {
    height: 64px;
    width: 383px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid;
    border-color: #0086d6;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  }
  .buttonContainerDisabled {
    height: 64px;
    width: 383px;
    pointer-events: none;
    background-color: white;
    border-radius: 15px;
    border: 1px solid;
    border-color: #bbbbbb;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  }
  .inputStyle {
    width: 320px;
    height: 56px;
    border-radius: 24px;
    border: 1px solid;
    border-color: #565656;
    font-size: 36px;
    font-weight: 600;
    font-family: Montserrat;
    color: #565656;
  }
}
