.checkbox {
  background-color: var(--primary);
  opacity: 0.1;
}

.cssCheckbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
#inputPreview {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.cssCheckbox + label {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: rgb(0, 0, 0);
}
.cssCheckbox + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: 18px;
  height: 18px;
  background-color: rgb(64, 163, 224);
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  box-shadow: rgba(64, 163, 224, 0.98) 1px 1px 20px 0px;
}
.cssCheckbox:checked + label::after {
  content: " ";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: transparent;
  height: 20px;
  width: 20px;
}

.noChecked {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
#inputPreview {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.noChecked + label {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: rgb(0, 0, 0);
}
.noChecked + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: 18px;
  height: 18px;
  background-color: rgb(218, 218, 218);
  border-width: 2px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  box-shadow: rgba(154, 154, 154, 0.98) 0px 0px 20px 0px;
}
