.container {
  max-width: 500px;
  width: 90%;
  height: 250px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.icon {
  width: 100px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 16px;
}

.buttonContinue {
  padding: 6px 8px;
  border: none;

  font-weight: 600;
  font-size: 0.875 rem;
  line-height: 24px;
  width: 100%;
  height: 36px;

  background: var(--primary);
  color: var(--secondary);
  border-radius: 5px;
  cursor: pointer;
}
.buttonError {
  padding: 6px 8px;
  border: none;

  font-weight: 600;
  font-size: 0.875 rem;
  line-height: 24px;
  width: 100%;
  height: 36px;
  cursor: pointer;

  background: var(--successDark);
  color: var(--secondary);
  border-radius: 5px;
}
