.container {
  align-items: flex-start;
  width: 275px;
  border-radius: 15px;
  background-color: var(--gray50);
  height: 277px;
  padding: 11px;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}
