.container {
  height: 100%;
  width: 100%;
}
.view {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.icon {
  width: 60px;
  height: auto;
}
.alignLeft {
  align-self: flex-start;
}

.warning {
  width: 90%;
  height: 85px;
  display: flex;
  column-count: 2;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--secondary);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 16px;
  align-self: flex-start;
  min-height: 110px;
  max-height: 170px;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 50%;
  height: 100%;
}

.flexItem1 {
  width: 300px;
  height: 100px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.downloader {
  height: 62px;
  width: 100%;
  background-color: var(--secondary);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.15);

  border: 1px solid var(--complementary2);
}
.flexItem2 {
  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.button {
  border-radius: 15px;
  padding: 12px;
  width: 180px;
  font-size: 0.8125rem;
  font-weight: 700;
  height: 44px;
  background-color: var(--complementary);
  border: 1px solid var(--complementary);
  color: var(--secondary);
  cursor: pointer;
}

.disabled {
  background-color: var(--gray400);
  color: var(--gray100);
  border: none;
  cursor: not-allowed;
}

.downloaderFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .flex {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .container {
    height: 100vh;
  }
  .flexItem2 {
    margin-left: 50px;
  }
  .flexItem1 {
    margin-left: 50px;
  }
  .button {
    align-self: flex-end;
  }
  .view {
    width: 90%;
  }
  .downloaderFlex {
    align-items: flex-start;
  }
  .warning {
    height: 85px;
  }
}
