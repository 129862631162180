.Container-SPConfig {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.background-SPConfig {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}

.innerContainer-SPConfig {
  width: 90%;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.commonContainer-SPConfig {
  flex-direction: row;
  display: flex;
  align-self: flex-start;
  padding-left: 16px;
}
.iconContainer-SPConfig {
  margin-right: 17px;
  cursor: pointer;
}
.buttonContinue-SPConfig {
  height: 56px;
  width: 100%;
  border-radius: 5px;
  background-color: #00bb2d;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  border: none;
}
.greyButton-SPConfig {
  height: 56px;
  width: 100%;
  border-radius: 5px;
  background-color: #565656;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  border: none;
}
.optionsContainer-SPConfig {
  margin-left: 30px;
  margin-right: 30px;
}

.innerMargin-SPCongig {
  width: 70%;
  margin-left: 60px;
}
