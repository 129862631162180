.container {
  max-width: 500px;
  width: 90%;
  height: 380px;
  padding-left: 16px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.icon {
  width: 100px;
}

.withoutImagesButton {
  font-weight: 700;
  font-size: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 16px;
}

.buttonBack {
  padding: 6px 8px;
  width: 48%;
  height: 48px;
  color: var(--secondary);
  background: var(--gray900);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.buttonContinue {
  padding: 6px 8px;
  border: none;
  width: 48%;
  height: 48px;
  background: var(--success3);
  color: var(--secondary);
  border-radius: 5px;
  cursor: pointer;
}
