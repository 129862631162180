.select-container {
  width: max-content;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  position: absolute;
  margin-top: -50px;

  overflow-y: scroll;
  max-height: 230px;
}
.select-text-style {
  color: #565656;
  margin-right: 9px;
}
.option-container-SC {
  display: flex;
  flex-direction: row;
}
.select-first-container {
  width: 60%;
}
