.ecoFriendContainer-commerce {
  width: 703px;
  height: 257px;
  border-radius: 7px;
  background-color: #ffffff;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  border: 1px solid #eeeeee;
  margin-top: 50px;
}

.container-commerce {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  width: 100%;
  padding-left: 72px;
  padding-top: 34px;
  height: 100vh;
}
.configTextStyle-commerce {
  color: #565656;
  padding-left: 14px;
  line-height: 0px;
}
.subtitleTextStyle-commerce {
  color: #787878;
  text-align: left;
}
.configTitleIconContainer-commerce {
  flex-direction: row;
  display: flex;
}
.iconStyleConfig-commerce {
  height: 18px;
  width: 20px;
  padding-right: 16px;
  padding-left: 30px;
}
.subtitleMargin-commerce {
  margin-right: 49px;
}
.ecofriendIconContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}
.grey-color-commerce {
  color: #565656;
  text-align: center;
}
.iconContainer-commerce {
  margin-left: 4px;
}
@media screen and (min-width: 501px) and (max-width: 999px) {
  .ecoFriendContainer-commerce {
    width: 550px;
    height: 300px;
    border-radius: 7px;
    background-color: #ffffff;
    padding-left: 15px;

    justify-content: center;
    align-items: center;
    padding-top: 15px;
    border: 1px solid #eeeeee;
    margin-top: 50px;
  }
}
