body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --primary: #0086d6;
  --primaryLight: #72c0f5;
  --primaryDark: #40a4e0;
  --secondary: #ffffff;
  --yellowPost: #ffea79;
  --warning: #f6c412;
  --complementary: #efa329;
  --complementary2: #edc255;
  --info: #1890ff;
  --success: #1e854b;
  --successDark: #00a853;
  --successLight: #00bb2d;
  --success3: #74bf00;
  --error: #e41b1b;
  --errorLight: #FF1E2F;
  --gray50: #eeeeee;
  --gray100: #dddddd;
  --gray200: #cccccc;
  --gray300: #bbbbbb;
  --gray400: #aaaaaa;
  --gray500: #9a9a9a;
  --gray600: #898989;
  --gray700: #787878;
  --gray800: #676767;
  --gray900: #565656;
  --gray10c: #63666a;
  --phoneWidth: 390px;
  --notebookWidth: 1280px;
  --desktopWidth: 2560px;
}
