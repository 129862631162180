.xIconContainer {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 28px;
  padding-right: 23px;
}

.IconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.backdrop {
  position: absolute;
  background: rgba(0, 0, 0, 0.49);
  height: 110%;
  width: 100vw;
  z-index: 10;
  top: 0;
  left: 0;
}

.modal-main {
  position: fixed;
  z-index: 500;
  background-color: var(--secondary);
  width: 98%;
  border: 1px solid #ccc;
  left: 1%;
  top: 20%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  overflow-y: auto;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  position: absolute;
}

.titleIconContainer {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.hastitleIconContainer {
  padding-top: 0px;
}

.commonPadding {
  padding-top: 28px;
}

.greyTitleModal {
  color: #565656;
}

.leftTitleMargin {
  margin-left: 10px;
}

@media only screen and (min-width: 450px) {
  .modal-main {
    width: 80%;
    padding: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 95%;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 768px) {
  .modal-main {
    background: white;
    width: max-content;
    top: 50%;
    overflow-y: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: 0px 0px 16px 0px;
    border-radius: 5px;
  }
}

@media only screen and (max-height: 500px) {
  .modal {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}