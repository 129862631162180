.containerModal {
  width: 300px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.labels {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: max-content;
}

.buttons {
  justify-content: center;
  align-items: center;
}

.button {
  margin-bottom: 8px;
}
.modalButtonBack {
  background-color: #eeeeee;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  color: #63666a;
  border: none;
  font-weight: bold;
}

.buttonBack {
  background-color: #0086d6;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
}
