.view{
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	overflow-y: hidden;
}

.container{
	width: 90%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;
	box-sizing: border-box;
	padding:16px
}

.column{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.row{
	display: flex;
	width: 100%;
}
.rowHeader{
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.rowFilters{
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.rowPercentage{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.wrap{
	flex-wrap: wrap;
}

.header{
	height: 35%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;

}
.spacedX{
	justify-content: center;
}
.rowSale{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
}

.salesList{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 80%;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	overflow-y: scroll;
}

.saleCard{
	width: 400px;
}
.totalAmmount{
	line-height: 0px;
}
@media only screen and (min-width: 768px) {
	.spacedX{
		justify-content: space-between;
	}
	.rowHeader{
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
	}
	.header{
		height: 30%;
	}
	.rowPercentage{
		width: 40%;
	}

	.rowFilters{
		width: 35%;
	}
}
@media only screen and (min-width: 1107px) {
	.rowSale{
		width: 48%;
		min-width: 361px;
	}
	
}