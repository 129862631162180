.formButton {
  background-color: var(--primary);
  color: var(--secondary);
  height: 56px;
  width: 100%;
  border-radius: 14px;
  border: transparent;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.formButton:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  background-color: #72c0f5;
}

.successButton {
  width: 289px;
  height: 36px;
  background-color: #0086d6;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.modalButtonBack {
  width: 289px;
  height: 36px;
  background-color: var(--gray900);
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.successBC {
  background-color: var(--success3);
  color: var(--secondary);
  border: none;
  width: 289px;
  height: 36px;
  border-radius: 5px;
  cursor: pointer;
}
.successDark {
  cursor: pointer;
  background-color: var(--successDark);
  color: var(--secondary);
  border: none;
  width: 289px;
  height: 36px;
  border-radius: 5px;
}

.fullwidth {
  width: 100%;
}

.maxContentWidth {
  width: max-content;
}

.buttonDisabled {
  background-color: var(--gray300);
  color: var(--secondary);
  pointer-events: none;
}

.error {
  width: 60%;
  background-color: var(--error);
  border-color: transparent;
  border-radius: 5px;
  padding: 4px 16px;
  color: var(--secondary);
}
.errorLight {
  width: 60%;
  background-color: var(--errorLight);
  border-color: transparent;
  border-radius: 5px;
  padding: 4px 16px;
  color: var(--secondary);
}
.successButton:active {
  background-color: #72c0f5;
}
