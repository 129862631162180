.orders-header-tab-container {
  height: 40px;
  width: 100%;
  background-color: #eeeeee;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.order-header-text-container {
  width: 25%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.order-header-text-container-selected {
  height: 40px;
  width: 25%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
}
.order-header-text-style {
  color: #63666a;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
