.buttonContinue-OSA {
  height: 56px;
  width: 820px;
  border-radius: 5px;
  background-color: #00bb2d;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  border: none;
  margin-bottom: 40px;
}
.background-OSA {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 72px;
  padding-top: 32px;
}
.commonContainer-OSA {
  flex-direction: row;
  display: flex;
}
.iconContainer-OSA {
  margin-right: 17px;
  cursor: pointer;
}
.innerContainer-OSA {
  width: 786px;
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: #ffffff;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.optionsContainer-OSA {
  margin-left: 30px;
  margin-right: 30px;
}

.innerMargin-OSA {
  width: 654px;
  margin-left: 60px;
}
.centeredCheckbox-OSA {
  justify-content: center;
  display: flex;
}
.cardContainer-OSA {
  width: 337px;
  height: 268px;
  border-radius: 25px;
  box-shadow: -3px 7px 14px rgba(0, 0, 0, 0.25);
}
.centeredCardContainer-OSA {
  justify-content: center;
  align-items: center;
  display: flex;
}
.greyButton-OSA {
  height: 53px;
  width: 343px;
  border-radius: 14px;
  background-color: white;
  border: 2px solid #565656;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 50px;
}
.titleStyle-OSA {
  font-weight: 700px;
  text-align: center;
}
.subtitleStyle-OSA {
  font-weight: 700px;
  text-align: center;
}
.innerCardContainer-OSA {
  padding-left: 20px;
  padding-right: 20px;
}
.cartIconContainer-OSA {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
}
.iconSize-OSA {
  width: 81px;
  height: 63px;
}
.greyButtonContainer-OSA {
  justify-content: center;
  align-self: center;
  display: flex;
}
