.background-pw {
  background-image: url("../../assets/images/bg_amiposWEB_login.png");
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.passwordContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.password {
  background-color: var(--secondary);
  box-shadow: 6px 6px 15px 0px #0000003b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.passwordform {
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonStyle-pw {
  width: 100%;
  height: 56px;
  background-color: #0086d6;
  border-radius: 14px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 5%;
  border: none;
}
.buttonStyle-pw:active {
  background-color: #72c0f5;
}
.buttonBack-pw {
  width: 162px;
  height: 40px;
  border: 1px solid #0086d6;
  background-color: #ffffff;
  border-radius: 24px;
  margin-top: 8%;
  color: #0086d6;
}
.buttonBack-pw:active {
  background-color: #006bab;
  color: #ffffff;
  border-color: #006bab;
}
.cardTitle-pw {
  text-align: center;
  color: #0086d6;
}
.leftIcon-pw {
  padding-right: 10px;
}
.bottomLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
  width: 50%;
  height: 11%;
}

@media only screen and (min-width: 768px) {
  .login {
    box-shadow: -5px 6px 10px 0px #00000040;
  }
}
@media only screen and (min-width: 1300px) {
  .login {
    box-shadow: -5px 6px 10px 0px #00000040;
  }
}
