.arrowLeftNavbar {
  width: 20px;
  margin-top: 10px;
  margin-right: 5px;
}
.arrowRightNavbar {
  width: 20px;
  margin-top: 10px;
  margin-left: 5px;
}
.arrowNavbar {
  cursor: pointer;
}
.container-navbar {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 0px;
  height: 33px;
  width: 95%;
}
.textNavBar {
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: 22px;
  font-style: normal;
  align-self: center;
  padding: 5px 16px 5px 16px;
}
.ul-navigation {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 32px;
  text-align: center;
}
.li-navigation {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-right: 10px;
  background-color: #dddddd;
  height: 32px;
  width: max-content;
}
.li-navigation:active {
  background-color: #0086d6;
  color: white;
}
.li-navigation.selected {
  background-color: #0086d6;
  color: white;
}
