.view {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  overflow-y: hidden;
}
.buttonContainer {
  width: calc(100% + 24px);
  margin-top: 20px;
}
.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.indicators {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px 7px 0px 0px;
  padding: 12px;
}
.lastMovementsRadius {
  border-radius: 7px;
  padding: 12px 12px 0px 12px;
}

.greenTitle {
  color: #229554;
  font-size: 48px;
  font-weight: 600;
  line-height: 0px;
}

.incress {
  font-weight: 600;
  color: #787878;
  line-height: 0px;
  font-size: 18px;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  gap: 16px;
}
.rowSale {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.column {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
}
.columnId {
  width: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
}

.columnEnd {
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;
}

.scrollColumn {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  height: 240px;
  box-sizing: border-box;
  overflow-x: scroll;
}

.saleCard {
  background: #eeeeee;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 40%;
    min-width: 400px;
  }
  .scrollColumn {
    height: 550px;
  }
}

@media only screen and (min-width: 1080px) {
  .scrollColumn {
    height: 240px;
  }
}

@media only screen and (min-width: 1320px) {
  .scrollColumn {
    height: 330px;
  }
}
