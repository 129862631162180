.container-ecoFr {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  width: 100%;
  padding-left: 72px;
  padding-top: 34px;
  height: 100vh;
}
.surveyContainer-ecoFr {
  width: 748px;
  border-radius: 7px;
  background-color: #ffffff;
  padding-left: 17px;
  margin-top: 22px;
  margin-bottom: 22px;
  padding-right: 17px;
  padding-bottom: 15px;
}
.titleContainer-ecoFr {
  flex-direction: row;
  display: flex;
}
.ecoFriendTitle {
  margin-left: 13px;
}

.questionColumnContainer {
  width: 328px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.responseColor {
  color: #565656;
}
.commonMarginTop {
  margin-top: 25px;
}
.buttonContinue-ecoFr {
  width: 100%;
  height: 56px;
  background-color: #00b744;
  border-radius: 14px;
  color: #ffffff;
  margin-top: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
.buttonContinue-ecoFr:active {
  background-color: #01a03c;
  color: #ffffff;
  border-color: #01a03c;
}
.buttonContinueDeactive-ecoFr {
  width: 100%;
  height: 56px;
  background-color: #bbbbbb;
  border-radius: 14px;
  color: #ffffff;
  margin-top: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: not-allowed;
}
.container {
  columns: 2 auto;
}
.modalsmallSurvey {
  height: 239px;
  width: 318px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.modalMediumSurvey {
  height: 316px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.centeredIcon-survey {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 15px;
}
.centeredText-survey {
  text-align: center;
}
.buttonContinue-survey {
  width: 85%;
  height: 36px;
  background-color: #00bb2d;
  border-radius: 5px;
  color: #ffffff;
  /* margin-top: 5%; */
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media screen and (min-width: 501px) and (max-width: 999px) {
  .surveyContainer-ecoFr {
    width: 548px;
    border-radius: 7px;
    background-color: #ffffff;
    padding-left: 17px;
    margin-top: 22px;
    margin-bottom: 22px;
    padding-right: 17px;
    padding-bottom: 15px;
  }
  .questionColumnContainer {
    width: 250px;
    
  }
}
