.buttonContinue-TCConfig {
  height: 56px;
  width: 90%;
  border-radius: 5px;
  background-color: #00bb2d;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  border: none;
  margin-bottom: 40px;
}
.buttonContinueDisabled-TCConfig {
  height: 56px;
  width: 90%;
  border-radius: 5px;
  background-color: #ccc;
  margin-top: 20px;
  color: white;
  border: none;
  margin-bottom: 40px;
  pointer-events: NONE;
}
.termsOverflow {
  padding: 16px;
  background-color: #f7f7f7;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 85%;
  max-height: 200px;
}

.background-TCConfig {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}
.commonContainer-TCConfig {
  flex-direction: row;
  display: flex;
  align-self: flex-start;
  padding-left: 16px;
}
.iconContainer-TCConfig {
  margin-right: 17px;
  cursor: pointer;
}
.innerContainer-TCConfig {
  width: 90%;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.optionsContainer-TCConfig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  gap: 16px;
  margin-left: 10%;
}

.innerMargin-TCCongig {
  width: 100%;
}
.centeredCheckbox-TCConfig {
  justify-content: center;
  align-self: center;
  display: flex;
}
