.container-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: max-content;
}
.text-title-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  line-height: 0px;
  margin-left: 5px;
}
.text-info-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3f3d3c;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}
th {
  display: table-cell;
  font-weight: initial;
  line-height: 1px;
  background-color: #f0f0f0;
  border: 1px solid #d7d7d7;
  text-align: left;
}
td {
  display: table-cell;
  font-weight: initial;
  line-height: 1px;
  background-color: white;
  border: 1px solid #d7d7d7;
  text-align: center;
}
.button-lidation {
  width: 127px;
  height: 26px;
  background: #74bf00;
  border: 1px solid #ffffff;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.button-lidation:active {
  background: #ffffff;
  border: 1px solid #565656;
  color: #565656;
}
.button-lidation:hover {
  cursor: pointer;
}
.button-stop-lidation {
  width: 127px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #565656;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565656;
}
.button-stop-lidation:active {
  background: #74bf00;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.button-stop-lidation:hover {
  cursor: pointer;
}
.thDescripcion {
  width: 255px;
}
.thDescripcion:hover {
  cursor: pointer;
}
.thPrecio:hover {
  cursor: pointer;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.edit-product {
  width: 127px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #565656;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565656;
  cursor: pointer;
}
