.checkboxSq {
  background-color: var(--primary);
  opacity: 0.1;
}
.containerSq {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.backgroundCheckboxSq {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #c9e4f5;
  justify-content: center;
  align-items: center;
  display: flex;
}
.cssCheckboxSq {
  height: 18px;
  width: 18px;
  margin: -1px;
  cursor: pointer;
  accent-color: #0086d6;
}

#inputPreviewSq {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.noCheckedSq {
  height: 18px;
  width: 18px;
  margin: -1px;
  cursor: pointer;
}
.hasSubtitleSq {
  flex-direction: column;
  display: flex;
  margin-left: 10px;
}
.noSubtitleSq {
  margin-left: 10px;
  align-items: center;
  display: flex;
}

.textStyleSq {
  color: #565656;
  line-height: 0px;
}
.subtitleTextStyleSq {
  margin-top: 0px;
  color: #565656;
  width: 290px;
}
