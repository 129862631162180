.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div.react-datepicker,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  border: none;
}
.react-datepicker {
  width: 100%;
  border: none;
}
.react-datepicker__month-container,
.react-datepicker__header {
  width: 100%;
  background-color: transparent;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.react-datepicker__month {
  width: 100%;
}
.react-datepicker__week {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.react-datepicker__day-names {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
