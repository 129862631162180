.containertop {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.container {
  background-color: #f5f5f5;
  width: 319px;
  border-radius: 10px;
  border: 1px solid;
  border-color: #0086d6;
  flex-direction: column;
  display: flex;
}
.titleStyle {
  color: #565656;
}
.commonMargin {
  margin-left: 17px;
  margin-right: 17px;
}
.backIconContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: 17px;
}
.arrowStyle {
  height: 11px;
  width: 11px;
  margin-right: 12px;
  margin-top: 12px;
}
.qrCodeContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #272729;
  background-color: #f5f5f5;
}
.buttonContainer {
  height: 56px;
  width: 243px;
  background-color: #029e28;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
}
.buttonContainerDisabled {
  height: 56px;
  width: 243px;
  pointer-events: none;
  background-color: #bbbbbb;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
}
.buttonContainer:active {
  background-color: #00bb2d;
}
.buttonTextStyle {
  color: white;
}
.modalStyle {
  width: 344px;
  height: 350px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .container {
    background-color: #f5f5f5;
    width: 419px;
    border-radius: 10px;
    border: 1px solid;
    border-color: #0086d6;
    flex-direction: column;
    display: flex;
  }
  .buttonContainer {
    height: 56px;
    width: 343px;
    background-color: #029e28;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
  .buttonContainerDisabled {
    height: 56px;
    width: 343px;
    pointer-events: none;
    background-color: #bbbbbb;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
}
