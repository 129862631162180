.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 0.25s, opacity 0.25s;
}

.modal.open {
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}

.errorButonText {
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;
	color: var(--secondary);
}

.header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.modalTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;

	display: flex;
	align-items: center;
	color: #565656;
}

.modalContent {
	position: relative;
	padding: 12px;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	max-width: 400px;
	width: 100%;
}

.closeButton {
	height: 24px;
	border: none;
	background: none;
	cursor: pointer;
}

.closeIcon {
	font-size: 24px;
	color: #888;
}

.closeIcon:hover {
	color: #555;
}