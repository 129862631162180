.xIconContainer-rm {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
}
.IconContainer-rm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
}
.backdrop-rm {
  position: fixed;
  background: rgba(0, 0, 0, 0.49);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-main-rm {
  position: absolute;
  background: white;
  width: 90%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 15px;
}
.modal-rm {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.main-container-rm {
  margin-left: 25px;
  margin-right: 25px;
  height: 95%;
  overflow-y: scroll;
}
.title-text-style-rm {
  color: #63666a;
}
.common-text-color {
  color: #565656;
}
.common-text-color-selected {
  color: #ffffff;
}
.alert-icon-container-rm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reject-option-button-rm {
  height: 28px;
  display: inline-flex;
  border-radius: 50px;
  background-color: #eeeeee;
  justify-content: left;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}
.reject-option-button-selected-rm {
  height: 28px;
  display: inline-flex;
  border-radius: 50px;
  background-color: #0086d6;
  justify-content: left;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

.reject-option-text-style-rm {
  padding-left: 20px;
  padding-right: 20px;
}
.reject-button-rm {
  margin-top: 40px;
  cursor: pointer;
  height: 56px;
  background-color: #e41b1b;
  border-radius: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: #ffffff;
}
.reject-button-disabled-rm {
  opacity: 0.3;
  pointer-events: none;
  background-color: gray;
  margin-top: 40px;
  height: 56px;
  border-radius: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: #ffffff;
}
.subtitle-text-style-rm {
  text-align: left;
  display: flex;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .modal-main-rm {
    position: absolute;
    background: white;
    width: 35%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: 0px 0px 16px 0px;
    border-radius: 15px;
  }
}

.display-block-rm {
  display: block;
}

.display-none-rm {
  display: none;
}
.modalSuccess-rm {
  height: 280px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.modalError-rm {
  height: 308px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
