.xIconContainer-com {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
  cursor: pointer;
}
.IconContainer-com {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 13px;
  padding-right: 11px;
}
.backdrop-com {
  position: fixed;
  background: rgba(0, 0, 0, 0.49);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-main-com {
  position: absolute;
  background: white;
  width: 600px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 15px;
  background-color: #f0f3f5;
}
.modal-main-comuneSelected {
  position: absolute;
  background: white;
  width: 600px;
  height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 15px;
  background-color: #f0f3f5;
  overflow-y: scroll;
}
.modal-com {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.main-container-com {
  margin-left: 25px;
  margin-right: 25px;
  height: 95%;
  overflow-y: scroll;
}
.textAlertStyle-com {
  color: white;
}
.alertTextContainer-com {
  margin-top: 37px;
  background-color: #565656;
  height: 44px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.iconAlertStyle-com {
  color: white;
  margin-right: 10px;
}
.searchInputStyle-com {
  margin-top: 37px;
  margin-left: 28px;
  margin-right: 28px;
  flex-direction: column;
}
.confirmButton-com {
  width: 549px;
  height: 56px;
  background-color: #00bb2d;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  color: white;
  bottom: 0;
  margin-bottom: 23px;
  margin-left: 28px;
}
.confirmButtonDeactive-com {
  width: 549px;
  height: 56px;
  background-color: #bbbbbb;
  border-radius: 14px;
  border: none;
  color: white;
  bottom: 0;
  margin-bottom: 23px;
  margin-left: 28px;
}
.listContainer-com {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 15px;
  max-height: 138px;
  padding-bottom: 20px;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.iconRegionContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.iconLocationContainer-com {
  margin-left: 10px;
  margin-right: 14px;
}
.selectedItemContainer-com {
  height: 36px;
  padding-left: 18px;
  padding-right: 22px;
  background-color: #cfedff;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 23px;
}
.innerSelectedItemContainer-com {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.iconXStyle-com {
  margin-left: 15px;
  cursor: pointer;
}
.costContainer-com {
  width: 117px;
  height: 34px;
  border: 1px solid #aaaaaa;
  border-radius: 25px;
  text-align: right;
  padding-right: 10px;
}
.commonSpaceBetween {
  justify-content: space-between;
}
.typeDeliver-com {
  justify-content: center;
  align-items: center;
  display: inline-block;
  border: 1px solid #0086d6;
  border-radius: 24px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: #0086d6;
  margin-bottom: 20px;
  margin-right: 25px;
  cursor: pointer;
}
.typeDeliverSelected-com {
  justify-content: center;
  align-items: center;
  display: inline-block;
  border: 1px solid #006bab;
  background-color: #006bab;
  border-radius: 24px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  margin-bottom: 20px;
  margin-right: 25px;
  cursor: pointer;
}
.grey900-com {
  color: #565656;
}
.selectorContainer-com {
  width: 100%;
  height: 48px;
  background-color: white;
  border: 1px solid #aaaaaa;
  border-radius: 25px;
  align-items: center;
  padding-left: 14px;
  display: flex;
  margin-bottom: 20px;
}
.noChecked-com {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.cssCheckbox-com {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  background-color: #0086d6;
  outline: none;
  cursor: pointer;
}
.commonContainer-com {
  margin-bottom: 25px;
}
