.loginform {
  padding: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalStyleContainer {
  width: 383px;
  height: auto;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.pilotModalContent {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

.pilotModalContent p {
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
}

.pilotModalButtons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.pilotModalButtons button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.pilotModalButtons button:first-child {
  background-color: #029e28;
  color: #ffffff;
  border: none;
}

.pilotModalButtons button:last-child {
  background-color: #40a4e0;
  color: #ffffff;
  border: none;
} 
.login-title {
  color: var(--primary);
  font-size: 1, 125rem;
  font-weight: 400;
  line-height: 1, 75rem;
  letter-spacing: 0px;
  text-align: center;
}
.login {
  background-color: var(--secondary);
  box-shadow: 6px 6px 15px 0px #0000003b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6%;
}

.bottomLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
  width: 50%;
  height: 11%;
}
.bottomLinksColor {
  color: #63666a;
}
.loginContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.textLogoStyle {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 250px;
}
@media only screen and (min-width: 768px) {
  .login {
    box-shadow: -5px 6px 10px 0px #00000040;
  }
  .textLogoStyle {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 322px;
  }
}
@media only screen and (min-width: 1300px) {
  .login {
    box-shadow: -5px 6px 10px 0px #00000040;
  }
  .textLogoStyle {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 323px;
  }
}
