.container {
  width: 100%;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.rowInput {
  width: 48%;
}

.rowInputLow {
  width: 42%;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-start;
  width: 100%;
}

.textWidth {
  min-width: max-content;
}

.divider {
  height: 0px;
  width: 80%;

  border: 1px solid #bbbbbb;
}

.addOptionButton {
  display: flex;
  flex-direction: row;
  width: 150px;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 10px 10px;
  border: none;
  background-color: var(--successDark);
  color: var(--secondary);
  margin-bottom: 16px;
}

.fullDivider {
  width: 100%;
  height: 1px;
  background-color: var(--gray100);
}

.bold {
  font-weight: 600;
  color: black;
}

.iconClick {
  cursor: pointer;
  width: 36px;
  height: auto;
}

.remove {
  width: 30px;
}
