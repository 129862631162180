.container-card-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 364px;
  height: 100%;
  background: #ffffff;
  box-shadow: -3px 7px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.container-card-info-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  gap: 50px;
  width: 338px;
  height: 100%;
}
.div-texts-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 215px;
  height: max-content;
  margin-bottom: 9px;
}
.text-card-producto-info {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #000000;
  margin: 0;
}
.text-card-producto-precio-stock {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #000000;
  margin: 0;
}
.button-liquidation {
  width: 127px;
  height: 26px;
  background: #74bf00;
  border: 1px solid #ffffff;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.button-liquidation:active {
  background: #ffffff;
  border: 1px solid #565656;
  color: #565656;
}
.button-stop-liquidation {
  width: 127px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #565656;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565656;
}
.button-stop-liquidation:active {
  background: #74bf00;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.twoButtonsContainer {
  flex-direction: row;
  flex: 1;
}
.button-edit {
  margin-left: 5px;
  width: 75px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #565656;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565656;
}
