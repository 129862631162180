.background-vs {
  background-image: url("../../assets/images/bg_site.png");
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.containerVoucher-vs {
  width: 759px;
  height: 463px;
  border-radius: 7px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}
.innerContainer-vs {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.uploadContainer-vs {
  width: 344px;
  height: 284px;
  background-color: #eeeeee;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px dashed #565656;
}
.titleContainer-vs {
  flex-direction: row;
  display: flex;
  margin-left: 15px;
}
.titleStyle-vs {
  width: 344px;
  color: #565656;
}
.titleStyleError-vs {
  width: 344px;
  color: red;
}
.titleIconStyle-vs {
  margin-left: 15px;
  color: #63666a;
}
.buttonContinue-vs {
  width: 759px;
  height: 50px;
  border-radius: 5px;
  background-color: #00bb2d;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 21px;
  color: #ffffff;
}
.buttonContinueDisabled-vs {
  width: 759px;
  height: 50px;
  border-radius: 5px;
  background-color: #cccccc;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 21px;
  color: #ffffff;
  pointer-events: none;
}
.modalsmall-vs {
  width: 260px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}
.modalstyle-vs {
  width: 344px;
  height: 333px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}
@media screen and (min-width: 501px) and (max-width: 999px) {
  .containerVoucher-vs {
    width: 600px;
    height: 383px;
    border-radius: 7px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
  }
  .titleStyle-vs {
    width: 244px;
    color: #565656;
  }
  .uploadContainer-vs {
    width: 264px;
    height: 204px;
    background-color: #eeeeee;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px dashed #565656;
  }
}
