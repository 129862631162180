.order-container-OC {
  background-color: #ffffff;
  width: 99%;
  border: 1px solid #40a4e0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.delivery-purchase-container-OC {
  background-color: #ffffff;
  width: 99%;
  border: 1px solid #565656;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.order-delivery-container-OC {
  background-color: #ffffff;
  width: 99%;
  border: 1px solid #565656;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.order-purchase-container-OC {
  background-color: #ffffff;
  width: 99%;
  border: 1px solid #74bf00;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.delivery-header-OC {
  background-color: #565656;
  height: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.delivery-icon-style-OC {
  height: 22px;
}
.purchase-header-OC {
  background-color: #74bf00;
  height: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.purchase-icon-style-OC {
  margin-right: 20px;
}
.purchase-title-style-OC {
  color: #ffffff;
  margin-right: 6px;
  margin-left: 20px;
}

.mobile-data-container-OC {
  flex-direction: row;

  align-items: center;
}
.mobile-data-buttons-container-OC {
  flex-direction: column;
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.mobile-data-column-container-OC {
  margin-left: 8px;
  margin-top: 5px;
}

.first-row-container-OC {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.detail-container-OC {
  background-color: #eeeeee;
  height: 39px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.data-container-OC {
  margin-right: 4%;
}
.title-container-OC {
  display: flex;
  margin-left: 22px;
  margin-top: 13px;
  width: 65%;
}
.text-style-OC {
  color: #565656;
  line-height: 10px;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.text-style-big-OC {
  color: #565656;
}
.buttons-container-OC {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.reject-button-OC {
  width: 104px;
  height: 36px;
  border-radius: 24px;
  border: 1px solid #565656;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 15px;
}
.accept-button-OC {
  width: 95px;
  height: 36px;
  border-radius: 24px;
  background-color: #00bb2d;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.common-text-color-OC {
  color: #565656;
}
.blue-button-style-OC {
  height: 36px;
  border-radius: 24px;
  background-color: #0086d6;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.blue-button-text-style-OC {
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
}
.blue-text-style-OC {
  color: #0086d6;
}
.icon-text-container-OC {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.process-buttons-container-OC {
  justify-content: center;
  display: flex;
  align-self: center;
}
.delivery-text-common-margin {
  margin-left: 10px;
}
.process-text-common-margin {
  margin-right: 14px;
}
.modalSuccess-OC {
  height: 280px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.modalError-OC {
  height: 298px;
  width: 344px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 414px) {
  .reject-button-OC {
    height: 26px;
    margin-left: 9px;
  }
  .accept-button-OC {
    height: 26px;
  }
  .buttons-container-OC {
    margin-right: 13px;
  }
  .detail-container-OC {
    height: 24px;
  }
  .text-style-OC {
    text-align: left;
    line-height: 15px;
  }
  .amount-text-style-OC {
    text-align: right;
    color: #565656;
  }
  .first-row-container-OC {
    justify-content: space-between;
  }
  .order-container-OC {
    background-color: #ffffff;
    width: 99%;
    border: 1px solid #40a4e0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .delivery-purchase-container-OC {
    background-color: #ffffff;
    width: 99%;
    border: 1px solid #565656;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .process-buttons-container-OC {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .blue-button-style-OC {
    height: 30px;
    width: 168px;
    border-radius: 24px;
    background-color: #0086d6;
    cursor: pointer;
  }
  .process-text-common-margin {
    margin-right: 14px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .blue-button-text-style-OC {
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
  }
} ;
