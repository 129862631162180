.subtitle1 {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0px;
}

.black {
  color: black;
}
.bold-600 {
  font-weight: 600 !important;
}
.noUnderline {
  text-decoration: none;
}
.ButtonMd-14 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}
.ButtonLg-16 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}
.ButtonSm-13 {
  font-family: Montserrat;
  font-size: 0.813rem;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0px;
  text-align: center;
}
.H4 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
}
.H5-20 {
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: 0px;
  text-align: center;
}
.colorGray {
  color: var(--gray900);
}
.H2 {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 600;
}
.H3 {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
}
.H5 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}
.H6 {
  font-family: Montserrat;
  font-size: 18;
  font-weight: 600;
}
.Caption1-14 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1px;
}
.Caption3-13 {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
}
.Caption3-light {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}
.Body1-16 {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
}
.Body2-14 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.Body3-12 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.warning {
  font-size: 0.813rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: var(--gray900);
}
.gray10c {
  color: var(--gray10c);
}
.bold {
  font-weight: 700;
}

.subtitle2 {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--gray10c);
}
.subtitle3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

.leftAlign {
  text-align: left;
}

.Caption3 {
  font-family: Montserrat;
  font-size: 0.813rem;
  font-weight: 600;
  line-height: 1.063rem;
  letter-spacing: 0px;
}
.Caption-Italic {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
}

@media only screen and (max-width: 768px) {
  .ButtonSm-13 {
    font-size: 0.65rem;
  }
}
.Body3-12 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.Badge-small {
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
}
.Small {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}
.Small-2 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}
.Small-3 {
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 400;
}
.Subtitle3 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}
.Subtitle2 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}
.md-medium {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}
