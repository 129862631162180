.background-OOSConfig {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}
.innerContainer-OOSConfig {
  width: 100%;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.commonContainer-OOSConfig {
  flex-direction: row;
  display: flex;
  align-self: flex-start;
  padding-left: 16px;
}
.iconContainer-OOSConfig {
  margin-right: 17px;
  cursor: pointer;
}
.buttonContinue-OOSConfig {
  height: 43px;
  border-radius: 5px;
  background-color: #00bb2d;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 40px;
  width: 100%;
  color: white;
  border: none;
}
.buttonContinueDisabled-OOSConfig {
  height: 43px;
  border-radius: 5px;
  background-color: #ccc;
  margin-top: 22px;
  margin-bottom: 40px;
  width: 80%;
  color: white;
  border: none;
  pointer-events: none;
}
.optionsContainer-OOSConfig {
  margin-left: 30px;
  margin-right: 30px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
.optionsContainer-OOSConfig-row {
  display: flex;
  flex-direction: row;
}
.alwaysDeliveryWidth {
  width: 245px;
}
.minAmountInputWidth {
  width: 95%;
}
.minAmountDescription-text-style {
  font-style: italic;
}
.commonTextAlignt-OOSConfig {
  text-align: left;
}
.minAmountInputContainer-OOSConfig {
  padding-left: 30px;
}
.centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bluebuttonStyle-OOSConfig {
  justify-content: center;
  align-items: center;
  display: inline-block;
  border: 1px solid #0086d6;
  background-color: #0086d6;
  border-radius: 24px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  margin-bottom: 20px;
  margin-right: 25px;
  cursor: pointer;
}
.typeDeliver-OOSConfig {
  justify-content: center;
  align-items: center;
  border: 1px solid #0086d6;
  border-radius: 24px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: #0086d6;
  margin-bottom: 20px;
  margin-right: 25px;
  cursor: pointer;
  margin-top: 20px;
}
.typeDeliverSelected-OOSConfig {
  justify-content: center;
  align-items: center;
  border: 1px solid #0086d6;
  background-color: #0086d6;
  border-radius: 24px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  margin-bottom: 20px;
  margin-right: 25px;
  cursor: pointer;
  margin-top: 20px;
}
.regionSelectedContainer-OOSConfig {
  width: 98%;
  border-radius: 5px;
  background-color: #eeeeee;
  margin-top: 20px;
  padding-left: 13px;
  padding-top: 13px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 833px) {
  .innerContainer-OOSConfig {
    width: 80%;
  }
  .buttonContinue-OOSConfig {
    width: 80%;
  }
}
