.background-OSConfig {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}
.textStyle-OSConfig {
  color: #565656;
  line-height: 16px;
}
.innerContainer-OSConfig {
  width: 100%;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.25);
}
.commonContainer-OSConfig {
  flex-direction: row;
  display: flex;
  align-self: flex-start;
  padding-left: 16px;
}
.iconContainer-OSConfig {
  margin-right: 17px;
  cursor: pointer;
}

.storeNameContainer-OSConfig {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.textContainer-OSConfig {
  margin-left: 23px;
  align-content: center;
  display: flex;
  justify-content: center;
}

.grey900-OSConfig {
  color: #565656;
}
.white-OSConfig {
  color: white;
}
.daysContainer-OSConfig {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.dayContainerBlue-OSConfig {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #40a4e0;
  border-color: #40a4e0 1px solid;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
}
.dayContainerGrey-OSConfig {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #565656;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #565656;
  cursor: pointer;
  margin-right: 20px;
}
.centered-OSConfig {
  align-items: center;
}
.spaceBetween-OSConfig {
  justify-content: space-between;
}
.commonWidth-OSConfig {
  width: 45%;
}

.divider-OSConfig {
  width: 45%;
  background-color: #676767;
  height: 1px;
}

.plusIconContainer-OSConfig {
  position: relative;
  top: -35px;
  left: 86px;
  cursor: pointer;
  z-index: 1;
}

.logo-img-OSConfig {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-left: 13px;
  position: relative;
  z-index: 0;
}
.timeInputContainer-OSConfig {
  border: none;
  cursor: pointer;
}
.emailContainer-OSConfig {
  max-width: 342px;
  min-width: 250px;
}
.receptionContainer-OSConfig {
  width: 50%;
}
.receptionTitle-OSConfig {
  margin-top: 20px;
}
.minAmmountContainer-OSConfig {
  width: 343px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.commonRow-OSConfig {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
.italicText-OSConfig {
  font-style: italic;
}
.lenghtCounter-OSConfig {
  justify-content: right;
  right: 0px;
  display: flex;
  margin-right: 20px;
}
.logoTitleStyle-OSConfig {
  margin-bottom: 40px;
}
.stepIndicatorContainer-OSConfig {
  flex-direction: row;
  display: flex;

  width: 80%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.SIContainer-OSConfig {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  width: 80%;
  flex-direction: column;
}
.stepIndicatorTextContainer-OSConfig {
  flex-direction: row;
  display: flex;
  width: 88%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepIndicatorCircle-OSConfig {
  width: 22px;
  height: 22px;
  border-radius: 30px;
  background-color: #0086d6;
  justify-content: center;
  align-items: center;
  display: flex;
}
.stepIndicatorCompleted-OSConfig {
  width: 22px;
  height: 22px;
  border-radius: 30px;
  background-color: #00bb2d;
  justify-content: center;
  align-items: center;
  display: flex;
}
.dividerStepIndicator-OSConfig {
  height: 1px;
  width: 87%;
  background-color: #676767;
  opacity: 1;
}
.stepIndicatorCircleContainer-OSConfig {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
}
.stepIndicatorCircleContainer-OSConfig-last {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 50px;
}
.stepIndicatorUnselectedCircle-OSConfig {
  width: 22px;
  height: 22px;
  border-radius: 30px;
  background-color: #ccc;
  justify-content: center;
  align-items: center;
  display: flex;
}
.stepInnerContainer-OSConfig {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: row;
}
.stepInnerContainer-OSConfig-last {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 22px;
  flex-direction: row;
}
.stepIndicatorLabelContainer-OSConfig {
  width: 80px;
  text-align: center;
}
.buttonContinue-OSConfig {
  height: 43px;
  border-radius: 5px;
  background-color: #00bb2d;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 40px;
  width: 100%;
  color: white;
  border: none;
}
@media only screen and (min-width: 833px) {
  .innerContainer-OSConfig {
    width: 90%;
  }
  .buttonContinue-OSConfig {
    width: 90%;
  }
}
