.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.modalsmall {
  height: 350px;
  width: 320px;
  background-color: #ffffff;
  padding: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.backdrop {
  position: absolute;
  height: 130vh;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}
.individualError {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 32px;
  background-color: var(--yellowPost);
  margin-right: -20px;
  margin-bottom: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
  gap:15px;
  margin-bottom: 30px;
}
.buttonWrapper{
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
  gap:8px;
}
.inputContainer {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}
.titleTextWrapper{
  align-self: self-start;
}
.form {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  box-shadow: -14px 14px 44px rgba(0, 0, 0, 0.25);
  height: 60%;
}

.button {
  background-color: #0086d6;
  padding-left: 20px;
  width: 100%;
  height: 56px;
  padding-right: 20px;
  border-radius: 14px;
  border: none;
  cursor:pointer;
  color: #ffffff;
}
.buttonDelete {
  background-color: #565656;
  padding-left: 20px;
  width: 100%;
  height: 56px;
  padding-right: 20px;
  border-radius: 14px;
  border: none;
  color: #ffffff;
  cursor:pointer;
}
.IsButtonDisabled {
  background-color: var(--gray300);
  color: #ffffff;
  cursor: not-allowed;
}
.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-start;
  width: 100%;
}

.textWidth {
  min-width: max-content;
}
.textColor {
  color: #565656;
}
.textAlign {
  text-align: left;
}
.divider {
  height: 0px;
  width: 80%;

  border: 1px solid #bbbbbb;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.rowItem1 {
  margin-right: 10px;
  width: 50%;
  height: 100%;
}
.rowItem2 {
  margin-left: 10px;
  width: 50%;
}

.liquidateError {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 32px;
  background-color: var(--yellowPost);
  margin-right: -20px;
}
.warningIcon {
  margin-left: 20px;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 90%;
  }
  .form {
    width: 100%;
    height: 100%;
  }
  .container {
    width: 600px;
    padding: 20px;
  }
}
