.drawer {
  height: 100vh;
  width: 250px;
  background-color: #ffffff;
  transform: translate(0);
  box-shadow: unset;
  box-shadow: 5px 8px 18px rgb(0, 0, 0, 0.3);
}
.header-dw {
  background-image: url("../../assets/images/drawer_amipos.png");
  height: 206px;
  width: 250px;
  flex-direction: column;
}

.titleStyle-dw {
  color: #ffffff;
  text-align: left;
  margin-left: 13px;
  margin-right: 13px;
}
.buttonText-dw {
  color: #565656;
}
.buttonText-drawer-dropdown-dw {
  color: #565656;
  margin-left: 26px;
}
.commerce-img {
  background-color: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-left: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-img {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-left: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-padding {
  padding-top: 15px;
  margin-left: 0px;
}
.left-icon-dw {
  padding-right: 13px;
  padding-left: 13px;
}
.arrow-icon-dw {
  padding-left: 50px;
  padding-right: 13px;
}
.icon-badge {
  height: 32px;
  width: 32px;
}
.badge-count-container {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  position: absolute;
  align-items: center;
}
.badge-circle-count-container {
  width: 24px;
  height: 24px;
  background-color: #ff0000;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
}
.text-badge {
  position: absolute;
  color: #ffffff;
  right: 5px;
  top: 2px;
  margin: 0 auto;
}
.button-container-dw {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.button-container-dw:active {
  background-color: #eeeeee;
}
.button-container-selected-dw {
  display: flex;
  flex-direction: row;
  background-color: #eeeeee;
  cursor: pointer;
}
.button-dropdown-dw {
  width: 250px;
  height: 45px;
  background-color: #eeeeee;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.line-dropdown-dw {
  width: 250px;
  height: 3px;
  background-color: #ffffff;
}
.line-dw {
  width: 250px;
  height: 1px;
  background-color: #dddddd;
}
.display-drawer-block {
  display: block;
}
.display-drawer-none {
  display: none;
}
.badge-circle-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 80px;
}

@media only screen and (max-width: 833px) {
  .mobile-header {
    height: 74px;
    width: 100%;
    background-color: #72c0f5;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .mobile-inner-header {
    width: fit-content;
    height: 74px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    background-color: #0086d6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-img-container {
    background-color: #ffffff;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-left: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-header-title {
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
  }
  .drawer {
    height: 100vh;
    width: 250px;
    background-color: #ffffff;
    transition: all 0.3Îs ease-in-out;
    transform: translate(0);
    position: absolute;
    z-index: 1;
  }
  .audioEl {
    width: 1px;
    height: 1px;
  }
  .drawer-closed {
    height: 0;
    width: 250px;
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
  }
  .backdrop {
    background: rgba(0, 0, 0, 0.49);
    height: 130vh;
    width: 100%;
  }
  .backdrop-close {
    width: 100%;
    height: 70px;
  }
  .logo-img-header {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-left: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .badge-icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 24px;
  }

  .logo-padding {
    padding-top: 34px;
    margin-left: 6px;
  }
  .header-dw {
    height: 263px;
  }
}
